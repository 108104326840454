<template>
  <span></span>
</template>

<script>
export default {
  name: 'SandboxHtml',
  props: {
    html: {
      type: String
    }
  },
  methods: {
    onLoad () {
      document.documentElement.innerHTML = this.html
      document.body.style['paddingTop'] = '30px'
    },
  },
  mounted () {
    this.onLoad()
  },
}
</script>

<style scoped>
.sandbox-html {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
}
</style>
